.session_1 {
  background-color: #ff2852;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 12%;
  left: 5%;
}

.session_2 {
  background-color: #0f42db;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 26%;
  left: 23%;
}

.session_3 {
  background-color: #ffb628;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 41%;
  left: 43%;
}

.session_4 {
  background-color: #0fdb54;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 56%;
  left: 53%;
}

.session_5 {
  background-color: #ff2852;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 71%;
  left: 63%;
}

.session_6 {
  background-color: #0f42db;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 84%;
  left: 70%;
}

.scrollbtns {
  border: none;
  border-radius: 5px;
  background-color: white;
}

.custom-container {
  overflow-y: scroll;
  max-height: 500px;
}

.custom-container::-webkit-scrollbar {
  width: 3px;
  /* Adjust the width as needed */
}

.custom-container::-webkit-scrollbar-thumb {
  background-color: blue;
  /* Change to your desired blue color */
  border-radius: 3px;
  /* Adjust the radius to make it thin */
}

.custom-container::-webkit-scrollbar-thumb:hover {
  background-color: darkblue;
  /* Change to your desired hover color */
}

.horizontal-container {
  overflow-x: auto;
  max-height: 100px;
}

.horizontal-container::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.horizontal-container::-webkit-scrollbar-thumb {
  background-color: blue;
  /* Change to your desired blue color */
  border-radius: 3px;
  /* Adjust the radius to make it thin */
}

.horizontal-container::-webkit-scrollbar-thumb:hover {
  background-color: darkblue;
  /* Change to your desired hover color */
}

.redbtn {
  border: none;
  background-color: #ff2852;
  color: white;
  border-radius: 10px;
  font-weight: 600;
}

.nobtn {
  border: none;
  background-color: white;
  color: black;
  font-weight: 600;
}

/* .horizontal-container {
    display: flex;
    overflow-x: auto;
  }
  
  .horizontal-container::-webkit-scrollbar-track {
    background-color: darkblue !important;
  } */

.session_11 {
  background-color: #ff2852;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 17%;
  left: 5%;
}

.session_22 {
  background-color: #0f42db;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 37%;
  left: 25%;
}

.session_33 {
  background-color: #ffb628;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 58%;
  left: 44%;
}

.session_44 {
  background-color: #0fdb54;
  padding: 0.8rem;
  border-radius: 8px;
  color: white;
  position: absolute;
  top: 79%;
  left: 51%;
}
@media only screen and (min-width: 992px) {
  .time_line {
    max-width: 30rem;
    background-color: #f5f5f580;
  }
}
@media only screen and (max-width: 768px) {
  .session_11 {
    background-color: #ff2852;
    padding: 0.8rem;
    border-radius: 8px;
    color: white;
    position: absolute;
    top: 17%;
    left: 5%;
  }
  .session_22 {
    background-color: #0f42db;
    padding: 0.8rem;
    border-radius: 8px;
    color: white;
    position: absolute;
    top: 37%;
    left: 25%;
  }
  .session_33 {
    background-color: #ffb628;
    padding: 0.8rem;
    border-radius: 8px;
    color: white;
    position: absolute;
    top: 58%;
    left: 44%;
  }
  .session_44 {
    background-color: #0fdb54;
    padding: 0.8rem;
    border-radius: 8px;
    color: white;
    position: absolute;
    top: 79%;
    left: 51%;
  }
}
