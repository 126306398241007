.footerbox:focus-visible {
  outline: none;
}

.footerbox {
  /* border-radius: 7px; */
  border: 1px solid white;
  /* border: none; */
  width: 100%;
  max-width: 260px;
  border-radius: 5px 0px 0px 5px;
}

.subscribebtn {
  background-color: #ff2852;
  max-width: 150px;
  border: 1px solid white;
  color: white;
  border-radius: 7px;
  height: 37.2px;
}

.newsLetter-input-field {
  background-color: white;
  max-width: 40rem;
  border-radius: 7px;
}

.footerblogs {
  font-size: 15px;
  color: white;
  font-family: 600;
}
.footer_background{
  background-image: url('../../assets/Mask\ group\ \(2\).png');
  background-color: #030406;
}
.footerbox::placeholder{
  font-size: 12px;
  color: #65737E;
}