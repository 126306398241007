* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.actions {
  color: black;
  font-size: medium;
  font-size: 18px;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.okaybtn {
  border: none;
  background-color: #0f42db;
  color: white;
  font-size: 15px;
  border-radius: 4px;
}

/* 
   
  /* navbar style start  */

.main-nav {
  width: 100%;
  height: 8rem;
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
  /* position: fixed; */
  /* top: 0; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.logo {
  display: grid;
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
}

.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  padding-top: 2rem;
  height: 8rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media {
  grid-column: 4/5;
}

.social-media ul {
  height: 8rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
}

.social-media ul li {
  text-align: right;
}

.social-media ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}

.menu-link ul li {
  font-size: 1.8rem;
}

.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
}

.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: underline;
  transition: 0.5s;
}

.social-media ul li {
  font-size: 1.8rem;
}

.social-media .hamburger-menu {
  display: none;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #c32aa3;
}

.youtube {
  color: #ff0000;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

/* WOrk Shop Free */
.workshop_Image {
  width: 100%;
}

.workshop_title {
  color: #ffffff;
  font-size: 34px;
}

.workshop_text {
  color: #ffffff;
  font-size: 14px;
}

.workshop_joinbutton {
  background-color: #030406;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}

.workshop_background {
  background-color: #0f42db;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  height: 42rem !important;
}

.alumini_img {
  width: 175px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.privacy_policy {
  max-width: 1200px;
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}

@media only screen and (max-width: 999px) {
  .copyfont {
    font-size: small;
  }

  .lefttext {
    margin-top: 4% !important;
  }
}

.enrollbtn {
  border-radius: 5px;
  background-color: #ff2852;
  color: white;
  border: none;
  font-size: 16px;
}

.enrollblubtn {
  border-radius: 5px;
  background-color: #0f42db;
  color: white;
  border: none;
  font-size: 16px;
}

.commenticon {
  color: #ff2852;
  /* font-size: 15px; */
}

.embark {
  font-size: 17px;
  /* width: 670px; */
  color: #969696;
}

.curriculumbtn {
  border: none;
  background-color: white;
  color: #ff2852;
  font-size: 19px;
  font-weight: 550;
}

.headerimg {
  left: 13%;
  top: 24%;
}

.lefttext {
  margin-top: 20% !important;
}

.background {
  background-color: #030406;
  /* background-image: url('../assets/Mask\ group\ \(2\).png'); */
}

.impressive {
  font-size: 50px;
  color: white;
}

/* .valueplus {
    font-size: 65px;
    color: white;
    line-height: -50px;
} */

.hirings {
  color: white;
  font-size: 45px;
}

.hiring {
  color: #0f42db;
  font-size: 45px;
}

.partnerss {
  color: white;
  font-size: 17px;
  font-weight: 550;
}

.partners {
  color: black;
  font-size: 17px;
  font-weight: 550;
}

.hiredstudents {
  color: white;
  font-size: 20px;
}

/* .hiredavg {
    color: black;
    font-size: 20px;
} */

/* .hiredbbg {
    background-color:
        #0F42DB;
    border-radius: 15px;
    width: 80%;
}

.partnersbg {
    background-color: #FF2852;
    border-radius: 15px;
    width: 80%;
}

.salarybg {
    background-color: white;
    border-radius: 15px;
    width: 80%;
} */

/* .cardswhitesqr {
    background: #ffffff;
    border: 1.4px solid #e0e3eb;
    border-radius: 4px;
    width: 55%;
} */

.colorCard {
  width: 100%;
  height: 150px;
  box-shadow: 0 0 15px #00000029;
  padding: 2rem !important;
  border-radius: 10px;
  background-color: #0f42db;
}

.colorCards {
  width: 100%;
  height: 150px;
  box-shadow: 0 0 15px #00000029;
  padding: 2rem !important;
  border-radius: 10px;
  background-color: #ff2852;
}

.colorCardss {
  width: 100%;
  height: 150px;
  box-shadow: 0 0 15px #00000029;
  padding: 2rem !important;
  border-radius: 10px;
  background-color: white;
}

.eligibility {
  background-color: #f7f7f7;
  border-radius: 10px;
  width: 100%;
  padding: 2rem;
  line-height: 2.5rem;
}

.eligibilities {
  font-size: 17px;
  color: black;
}

.eliggraduate {
  font-size: 27px;
  color: black;
  line-height: 2.5rem;
}

.eligibg {
  background-color: #ff2852;
  border-radius: 10px;
  width: 100%;
  padding: 3rem;
  line-height: 2.5rem;
}

.studynow {
  font-size: 22px !important;
  color: white;
  letter-spacing: 1px;
}

.footerwidthline {
  width: 100%;
  border-top: 3px solid #ffffff; /* Use a thicker white line (adjust the thickness as needed) */
}

.subscribe {
  font-size: 18px;
  color: white;
}

.quicklink {
  font-size: 18px;
  color: white;
  font-family: 600;
}

.footaddress {
  width: 60%;
}

.copyfont {
  font-size: 24px;
  color: white;
}

@media only screen and (max-width: 999px) {
  .copyfont {
    font-size: small;
  }

  .lefttext {
    margin-top: 4% !important;
  }
}

@media only screen and (max-width: 1040px) {
  .copyfont {
    font-size: small;
  }

  .hiring {
    color: #0f42db;
    font-size: 34px;
  }
}

.inputfields {
  background: #ffffff;
  border: 1.5px solid #dae8f2;
  border-radius: 7px;
  font-size: 15px;
  padding: 7px 0px 7px 0px;
}

.inputfieldserror {
  background: #ffffff;
  border: 1.5px solid red;
  border-radius: 7px;
  padding: 7px 0px 7px 0px;
}

.errorcolor {
  color: red !important;
  font-size: 14px;
}

.inputfields::placeholder {
  color: #65737e;
  font-size: 13px;
}

.inputfields::focus-visible {
  outline: none;
}

.labels {
  font-size: 13px;
  color: black;
  font-weight: 500;
}

.contactus {
  color: #030406;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.contactinfo {
  color: #030406;
  font-size: 16px;
}

.submitbtn {
  color: white;
  border: none;
  background-color: #0f42db;
  border-radius: 4px;
  font-size: 16px;
}
.inactivebtn {
  /* background-color: #dcdcdc; */
  background-color: #8499d7;
  color: #ffffff;
  cursor: not-allowed;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}
.submit_inactive_btn {
  background-color: #9eb3f2; /* Initial disabled color */
  color: #ffffff;
  cursor: not-allowed;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}
.title {
  color: white;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.subtitle {
  font-size: 16px;
  color: #969696;
  /* width: 40%; */
}

.titles {
  color: white;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.subtitles {
  font-size: 16px;
  color: white;
  /* width: 20%; */
}

.titlepayment {
  color: black;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.connectbtn {
  border: none;
  background-color: white;
  color: #0f42db;
  font-size: 15px;
  border-radius: 4px;
  width: 200px;
  font-weight: 600 !important;
}

.cardpayment {
  border: 1px solid black;
  border-radius: 10px;
  width: 300px;
}

.topbg {
  background-color: black;
  background-image: url("../assets/Rectangle\ 22.png");
  border-radius: 10px 10px 0px 0px;
}

.fullstack {
  color: white;
  font-size: 12px;
  letter-spacing: 0.1px;
}

.studynows {
  color: white;
  font-size: 30px;
  letter-spacing: 0.1px;
  line-height: 2.5rem;
}

.feedetails {
  font-size: 13.5px;
  color: #030406;
}

.joinnowbtn {
  /* margin-top: 38.5px; */
  border: none;
  background-color: #0f42db;
  color: white;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 500;
}

.joinnowbtns {
  background-color: #ff2852;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 500;
}

.subtitless {
  color: black;
  font-weight: 600;
  font-size: medium;
}

.internship {
  font-size: 17px;
  color: #969696;
}

.syllabusbtn {
  border: none;
  background-color: #0f42db;
  color: white;
  font-size: 16px;
  border-radius: 4px;
}

.bgcolor {
  background-color: #f5f5f5;
}

.hiringprtnrs {
  display: inline-block;
  position: relative;
  margin: 0 10px;
}

.workshop_background {
  background-color: #0f42db;
  /* padding: 220px 40px 220px 100px; */
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  height: 42rem;
}

@media only screen and (min-width: 992px) {
  .hiringprtnrs::before,
  .hiringprtnrs::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 300px;
    height: 2px;
    background-color: #e3e3e3;
    transform: translateY(-50%);
  }

  .hiringprtnrs::before {
    left: -320px;
  }

  .hiringprtnrs::after {
    right: -320px;
  }
}

.meeting_img {
  max-width: 60rem;
}

@media only screen and (min-width: 768px) {
  /* .hiringprtnrs::before {
    left: -270px;
  }

  .hiringprtnrs::after {
    right: -270px;
  } */
}

@media only screen and (max-width: 768px) {
  .fs_30 {
    font-size: 30px;
  }

  .quotes {
    width: 40px !important;
  }
}

@media (max-width: 480px) {
  .impressive {
    font-size: 30px;
    color: white;
  }

  .titlepayment {
    font-size: 30px;
  }

  .eliggraduate {
    font-size: 25px;
    line-height: 3rem;
  }

  .studynows {
    font-size: 25px;
    line-height: 3rem;
  }
}

@media only screen and (max-width: 1200px) {
  .workshop_background {
    background-color: #0f42db;
    /* padding: 220px 40px 220px 100px; */
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    height: 35rem;
  }

  .meeting_img {
    max-height: 48rem;
    max-width: 60rem;
  }
}

.Toggle_Navbar {
  background-color: #ffffff;
}

.dots::before {
  content: "•";
  font-weight: bold;
  font-size: 25px;
  margin-right: 10px;
}

.listitems {
  font-size: 16px;
  font-weight: 550 !important;
  line-height: 2rem;
}

.fontbold {
  font-weight: 600;
}

.Toggle_Navbar {
  background-color: #ffffff;
}

.Navbar_Bg {
  background-color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .workshop_background {
    background-color: #0f42db;
    border-radius: 14px;
    padding: 10px;
  }

  .rolls_fit_background {
    background-color: #ff2852 !important;
    border-radius: 35px;
    margin: 0px 20px 0px 20px !important;
  }

  .rolls_fit_title {
    color: #ffffff;
    font-size: 18px;
    padding: 0px 50px 0px 50px !important;
  }

  .workshop_Image {
    width: 100%;
  }

  .rolls_fit_head {
    color: #ffffff;
    font-size: 30px !important;
  }

  .rolls_fit_title {
    color: #ffffff;
    font-size: 14px !important;
    padding: 0px 200px 0px 200px;
  }

  .rolls_fit_availbox_title {
    font-weight: 500;
    font-size: 16px !important;
  }

  .apply_now_btn {
    background-color: #030406;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 12px;
  }
}

.rolesBackImg {
  background-image: url("../assets/Group 41.png");
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 14px;
}

.rolesMainSection {
  position: absolute;
  top: 100px;
  left: 810px;
}

.roles_mainTitle {
  color: #ffffff;
  font-size: 44px;
}

.position-relative {
  position: relative;
}

.rolls_fit_background {
  background-color: #ff2852;
  font-weight: 600;
}

.Toggle_Navbar {
  background-color: #ffffff;
  position: fixed;
  top: 8;
  left: 0;
  z-index: 1000;
  width: 100%;
}

.Navbar_Bg {
  background-color: #ffffff;
}

.workshop_Image {
  width: 85%;
}

.workshop_Image {
  width: 100%;
}

.workshop_text {
  color: #ffffff;
  font-size: 15px;
}

.workshop_joinbutton {
  background-color: #030406;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 992px) {
  .workshop_background {
    background-color: #0f42db;
    border-radius: 14px;
    padding: 10px;
  }

  .appproved_successfully_container {
    max-width: 34rem !important;
    max-height: 10rem;
  }

  /* .appproved_successfully_container {
    width: 100% !important;
    max-width: 35rem !important;
  } */

  .create_password_modal_backdrop {
    background: #0f203cb3 0% 0% no-repeat padding-box !important;
    opacity: 0.85 !important;
  }

  .appproved_form_container {
    max-width: 50rem !important;
  }

  .custom-modal-content {
    border-radius: 10px;
  }

  .create_form_modal_backdrop {
    background: #0f203cb3 0% 0% no-repeat padding-box !important;
    opacity: 0.85 !important;
  }

  .successtitle {
    color: black;
    font-size: 18px;
    font-weight: 600;
  }

  .okaybtn {
    border: none;
    background-color: #0f42db;
    color: white;
    font-size: 15px;
    border-radius: 4px;
  }

  .modal_heading {
    font-size: 32px !important;
    color: black;
    font-weight: 600;
  }

  .rolls_fit_background {
    background-color: #ff2852 !important;
    border-radius: 35px;
    margin: 0px 20px 0px 20px !important;
  }

  .rolls_fit_title {
    color: #ffffff;
    font-size: 18px;
    padding: 0px 50px 0px 50px !important;
  }

  .workshop_Image {
    width: 100%;
  }

  .rolls_fit_head {
    color: #ffffff;
    font-size: 30px !important;
  }

  .rolls_fit_title {
    color: #ffffff;
    font-size: 14px !important;
    padding: 0px 200px 0px 200px;
  }

  .rolls_fit_availbox_title {
    font-weight: 500;
    font-size: 16px !important;
  }

  .apply_now_btn {
    background-color: #030406;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    font-size: 16px !important;
  }
}

@media (max-width: 1200px) {
  .workshop_background {
    /* background-color: #0f42db; */
    background-image: url("../assets/Mask\ group\ \(1\).png");
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    height: 37rem !important;
  }
}

.rolesBackImg {
  background-image: url("../assets/Group 41.png");
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 14px;
}

.rolesMainSection {
  position: absolute;
  top: 100px;
  left: 810px;
}

.roles_mainTitle {
  color: #ffffff;
  font-size: 44px;
}

.position-relative {
  position: relative;
}

.rolls_fit_background {
  background-color: #ff2852;
  border-radius: 35px;
  margin: 0px 100px 0px 100px;
  position: relative;
}

.rolls_fit_head {
  color: #ffffff;
  font-size: 44px;
}

.rolls_fit_title {
  color: #ffffff;
  font-size: 18px;
  padding: 0px 250px 0px 250px;
}

.rolls_fit_availbox {
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  max-width: 250px;
}
.rolls_fit_availbox_full_stack {
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  max-width: 250px;
}
.rolls_fit_availbox_front_end {
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  max-width: 285px;
}
.rolls_fit_availbox_title {
  font-weight: 500;
  font-size: 21px;
}

.roles_Inputbox {
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
}

.rolls_fit_backImg {
  background-image: url("../assets/Intersect.png");
  background-repeat: no-repeat;
  background-position: left bottom;
}

.apply_now_btn {
  background-color: #030406;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 22px;
}

.rolesBackImg {
  background-image: url("../assets/Group 41.png");
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 14px;
}

.rolesMainSection {
  position: absolute;
  top: 100px;
  left: 810px;
}

.roles_mainTitle {
  color: #ffffff;
  font-size: 44px;
}

.position-relative {
  position: relative;
}

.apply_now_btn {
  background-color: #030406;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 20px;
}

.rolls_fit_background {
  background-color: #ff2852;
  border-radius: 35px;
  margin: 0px 100px 0px 100px;
  position: relative;
}

.rolls_fit_head {
  color: #ffffff;
  font-size: 44px;
}

.rolls_fit_title {
  color: #ffffff;
  font-size: 18px;
  padding: 0px 250px 0px 250px;
}

.rolls_fit_title_new {
  color: #ffffff;
  font-size: 18px;
}
.rolls_fit_availbox {
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  max-width: 350px;
}

.rolls_fit_availbox_title {
  font-weight: 500;
  font-size: 20px;
}

.roles_Inputbox {
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
}

.rolls_fit_backImg {
  background-image: url("../assets/Intersect.png");
  background-repeat: no-repeat;
  background-position: left bottom;
}

.apply_now_btn {
  background-color: #030406;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 20px;
}

.navbar-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  padding: 15px 0px 12px 0px;
}

@media only screen and (min-width: 992px) {
  .workshop_background {
    /* background-color: #0f42db; */
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    background-image: url("../assets/Mask\ group\ \(1\).png");
  }

  .workshop_card_container {
    max-width: 70rem;
  }
  .partners_container {
    margin-top: 6rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .workshop_title {
    color: #ffffff;
    font-size: 24px;
  }

  .titlepayment {
    color: black;
    font-size: 25px;
  }

  .alumini_img {
    width: 150px;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
}

.titleheading {
  font-size: 23px;
  color: black;
  font-weight: 500;
}

.titleheadings {
  font-size: 23px;
  color: black;
  font-weight: 600;
}

.Enroll_Modal_Title {
  font-size: 14px;
  color: #7f7f7f;
}

.Enroll_Modal_Closeicon {
  cursor: pointer;
}

.selectfonts {
  font-size: medium !important;
}

.Enroll_Modal_SelectBox {
  position: relative;
}

.Enroll_Modal_ddIcon {
  position: absolute;
  top: 8.4px;
  right: 8.4px;
}

.dropDownSvgIcon {
  position: relative;
  right: 12px;
}
.faq_cursor {
  cursor: pointer;
}
.getOtp {
  font-size: 14px;
  font-weight: 500;
  color: #ff2852;
  /* position: absolute;
  top:344px;
  right:36px; */
  cursor: pointer;
}
.resendOtp {
  font-size: 14px;
  font-weight: 500;
  color: #0f42db;
  /* position: absolute;
  top:438px;
  right:36px; */
  cursor: pointer;
}
.verifyOtp {
  font-size: 14px;
  font-weight: 500;
  color: #079130;
  cursor: pointer;
}

input:focus {
  outline: none;
}
.countrycode_ipbox {
  /* border-color: 2px solid rgb(165, 231, 212); */
  border: none;
  font-size: 12px;
  width: 100%;
  max-width: 60px;
}
.mobilenumb_ipbox {
  width: 100%;
  font-size: 15px;
}
.mobnumbinputfields {
  background: #ffffff;
  border: 1.5px solid #dae8f2;
  border-radius: 4px;
  font-size: 15px;
}

.mobnumbinputfieldserror {
  background: #ffffff;
  border: 1.5px solid red;
  border-radius: 4px;
}

.get-otp-disabled {
  font-size: 14px;
  font-weight: 500;
  color: #ff2852;
  opacity: 0.5;
}
.timer {
  font-size: 14px;
  font-weight: 500;
  color: #0f42db;
}
.hidden {
  display: none;
}

.text_underline_decoration {
  position: relative;
}
.text_underline_decoration::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.175rem;
  background: #ff2852;
  left: 0rem;
  top: 2.5rem;
}
.image_design {
  filter: grayscale(100%);
}


